export default {
    preface: {
        title: "Preface",
        body: `This is a documentation attempt for Advanced Look Controls (ALC) for Apex Legends. The goal is to provide a reference/guide for new players looking to make the switch to ALC settings or veterans that need a quick update on what a particular option does.

Choosing the right sensitivity largely depends on personal preference, the target outcome for any setting you create should be to make you feel confident in your ability to hit your targets in fights. The suggestions included in the ALC options on this page are there to give you an idea on how each option can affect your aim and doesn’t try to do anything else beyond that.
        
This is a work in progress and will be updated with time. If you have a suggestion or feedback, please visit the [discord](https://discord.gg/QwtXZhwms7).
****`
    },
    deadzone: {
        title: "Deadzone",
        body: `Deadzone determines how much you have to move your right-stick before your aim/camera responds. 

This is typically used to manage stick drift. If you increase the value a lot, your aim becomes unresponsive, if it becomes too small your aim becomes “floaty” and that makes staying on target harder.

The value you set for deadzone should be just enough to counter drift on your controller and keep your aim firm.
****`
    },
    outerthreshold: {
        title: "Outer Threshold",
        body: `Outer Threshold determines how much you have to move the right-stick before you reach max-sensitivity. In other words, increasing this makes your stick inputs feel a lot more sensitive.
        
The higher your **Outer Threshold**, the shorter the distance you have to move your stick to reach your max-sensitivity. Ideally you should leave this at default value but you may change them for these reasons:

* If you feel your “flicks” are too short on your shotguns or other semi-automatic weapons, adjusting this value can help.
* If you need your movement turns to be sharper/faster.
* You may have a faulty controller that is naturally stiffer than normal, you can use this option to make it more responsive.

In all the scenarios listed above, you should only increase the **Outer Threshold** by one or two ticks. High outer threshold values can make your aim too twitchy.

You will notice that the lowest value for Outer Threshold is 'one', that's because the game needs to maintain a constant zone where “Extra Yaw” and “Extra Pitch” will be applied. If you increase this value, you increase this zone. More on this in their respective sections.
****`
    },
    responsecurve: {
        title: "Response Curve",
        body: `Response Curve represents the shape of magnitude remapping applied to stick input. This means that **instead** of a linear progression of input magnitude, there is a gradual increase in magnitude based on the **Response Curve** value until your max-sensitivity is reached. 

Increasing this value will make your aim response feel slower initially and gradually increase over time. This makes **Response Curve** especially useful for small/minute adjustments required for tracking and to help players with “twitchy” aim smoothen their aim while also taking full advantage of aim assist.

Setting this value to **zero** means that your aim is mapped 1:1 to stick input. Any stick input you make is directly applied as is to your aim. This is useful for players moving from other input methods to a controller and want to retain the same “feel” for their aim on their previous input method.
***`
    },
    yaw: {
        title: "Yaw",
        body: `Yaw determines your horizontal hip-fire/movement sensitivity or how quickly your view moves left or right when **not** aiming down sight.

It is often tempting to increase this value as high as possible but there are some things to note:
        
- **Yaw** should only be high enough to let you comfortably track a target while hip-firing. If you wish to increase **only your** turn/movement speed, consider increasing **Extra Yaw** and/or **Outer Threshold** instead.
- **Yaw** (and **pitch**) affects your target acquisition when you ADS. Your hip-fire sensitivity should match your ADS sensitivity in such a way that you **don't** lose/gain momentum when you transition into ADS. It should feel smooth and seamless.
****`
    },
    pitch: {
        title: "Pitch",
        body: `Pitch determines how fast your camera responds when you move your stick up or down when not in ADS mode. You can think of it as your vertical hipfire sensitivity. 

It is often better to have a lower **pitch** than **yaw** in your setting, as stick input can be imprecise. For example, when attempting to make a horizontal turn, horizontal input can overflow into pitch, resulting in some vertical movement and a loss of horizontal velocity. Lowering the pitch relative to the yaw reduces this effect.

However, if you feel more comfortable having your **pitch** the same as your **yaw** because it gives more freedom when tracking movement in both axes, you can set them that way. 

Your **pitch** can’t be higher than your **yaw.**
****`
    },
    turningextrayaw: {
        title: "Turning Extra Yaw",
        body: `W.I.P.`
    }
}