export default {
    alc: {
        title: "Loading ALC...",
        deadzone: {
            name: "Deadzone",
            value: 2
        },
        outertreshold: {
            name: "Outer Treshold",
            value: 6
        },
        responsecurve: {
            name: "Response Curve",
            value: 8
        },
        yaw: {
            name: "Yaw",
            value: 300
        },
        pitch: {
            name: "Pitch",
            value: 220
        },
        turningyaw: {
            name: "Turning Extra Yaw",
            value: 32
        },
        turningpitch: {
            name: "Turning Extra Pitch",
            value: 32
        },
        turningrampup: {
            name: "Turning Ramp-up Time",
            value: 5
        },
        turningdelay: {
            name: "Turning Ramp-up Delay",
            value: 0
        },
        adsyaw: {
            name: "ADS Yaw",
            value: 200
        },
        adspitch: {
            name: "ADS Pitch",
            value: 170
        },
        adsturningyaw: {
            name: "ADS Turning Extra Yaw",
            value: 30
        },
        adsturningpitch: {
            name: "ADS Turning Extra Pitch",
            value: 30
        },
        adsturningrampup: {
            name: "ADS Turning Ramp-up Time",
            value: 5
        },
        adsturningdelay: {
            name: "ADS Turning Ramp-up Delay",
            value: 0
        },
        timeadded: 156389283928,
        tension: "Normal",
        description: "",
        videolink: "",
        platform: "Playstation",
        controller: "DualSense"
    },
    popular: [
        {
            name: "DZ Genburten",
            channel: "https://twitch.tv/genburten",
            profile: "2 time ALGS Champion",
            internalname: "Genburten",
            timeupdated: 1671284740175
        },
        /*{
            name: "FURIA Pandxrz",
            channel: "https://twitch.com/pandxrz",
            internalname: "Pandxrz",
            timeupdated: 1671284740175
        }, */
        {
            name: "Extessy",
            channel: "https://twitch.tv/extessy",
            internalname: "Extessy",
            timeupdated: 1671284740175
        },
        {
            name: "vUnlucky",
            channel: "https://twitch.tv/vunlucky",
            profile: "Pro player for Vexed Gaming",
            internalname: "vUnlucky",
            timeupdated: 1671284740175
        },
        {
            name: "iGrindApexAgain",
            channel: "https://twitch.tv/iGrind",
            internalname: "iGrindApex",
            profile: "#1 Ash and #3 Pred on Playstation",
            timeupdated: 1671284740175
        },
        
        {
            name: "Scrappy",
            channel: "https://twitch.tv/scrappy",
            profile: '#1 Loba all platforms',
            internalname: "Scrappy",
            timeupdated: 1671284740175
        },
        {
            name: "Termk47",
            channel: "https://twitch.tv/termk47",
            profile: "",
            internalname: "Termk47",
            timeupdated: 1671284740175
        },
        
    ]
}