<template>
    <div class="doc-root">
        <div class="doc-action-bar">
            <span @click="toggleDrawer()" @click.stop class="nav-action"><i data-feather="menu" class="nav-action-icon"></i>
                <span class="nav-action-text"> Navigation</span>
            </span>
            <a href="#top" class="scroll-top" v-smooth-scroll> Scroll to top</a>
        </div>
        <div class="content-index" :class="showDrawer" @click.stop v-on-clickaway="closeDrawer">
            <span class="index-title">ALC Settings</span>
            <hr />
            <ul class="indexes-container">
                <li v-for="docKey in Object.keys(docs)" :key="docs[docKey].title" class="alc-index">
                    <a :href="`#${docKey}`" v-smooth-scroll @click="closeDrawer()">{{ docs[docKey].title }}</a>
                </li>
            </ul>
        </div>
        <div class="doc-body">
            <span id="top"></span>
            <h1>ALC Guide</h1>
            <hr />
            <div v-for="docKey in Object.keys(docs)" :key="docs[docKey].title">
                <span :id="docKey"></span>
                <h2 class="doc-title">{{ docs[docKey].title }} <a :href="`#${docKey}`" class="doc-anchor"
                        v-smooth-scroll>#</a></h2>
                <div :ref="docKey"> </div>
            </div>

            <div class="page-promos">
                <router-link class="promo-card" to="/popular">
                    <span class="title"> See the ALCs professional players are using. </span>
                    <span class="sub-title"> Get some inspiration or a foundation to start finding the right setting for
                        you. </span>
                </router-link>

                <router-link class="promo-card" to="/addalc">
                    <span class="title"> Save your own ALCs here. </span>
                    <span class="sub-title"> Use the information gained from the guide to make an ALC tailored to you.
                    </span>
                </router-link>

            </div>

        </div>

    </div>
</template>

<script>
import docs from '../docs';
import marked from 'marked';
import feather from 'feather-icons';
import { mixin as clickaway } from 'vue-clickaway';
import { setPageDescription } from '../local';

export default {
    data() {
        return {
            docs: docs,
            showDrawer: "show-drawer"
        }
    },
    mixins: [clickaway],
    mounted() {
        feather.replace();

        Object.keys(this.docs).map((key) => {
            this.$refs[key][0].innerHTML = marked.parse(this.docs[key].body);
        })

        document.title = "Apex ALC Guide / Apex Legends ALC Guide";
        const description = "A manual or documentation on how to use Apex's ALC settings.";

        setPageDescription(document, description);

        this.$gtag.pageview("/guide");
    },
    methods: {
        toggleDrawer() {
            this.showDrawer = "drawer-anim";
        },
        closeDrawer() {
            if (this.showDrawer == "drawer-anim") { // if drawer is visible in mobile mode
                this.showDrawer = "hide-drawer-anim"

                setTimeout(() => {
                    this.showDrawer = "show-drawer";
                }, 350)
            }
        }
    }
}
</script>

<style>
.doc-root {
    margin-left: 300px;
    margin-right: 300px;
    color: rgb(227, 227, 227);
    font-family: 'Inter', 'Open Sans';
    font-feature-settings: "cv11", "ss01";
    font-size: 15px;
    display: flex;
    background-color: #353D73;
    border-right: 1px solid #1F2444;
    border-left: 1px solid #1F2444;
    border-bottom: 1px solid #1F2444;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    scroll-behavior: smooth;
    margin-bottom: 30px;
}

.index-title {
    font-weight: 550;
    font-size: 12px;
    color: #d6d6d6b3;
}

.content-index hr {
    border-color: #c0c0c041;
}

.page-promos {
    display: flex;
    justify-content: space-between;
}

.page-promos .promo-card {
    padding: 30px;
    border-radius: 5px;
    background-color: #2D3465;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none !important;
}

.page-promos .promo-card:hover {
    outline: 1px solid #FF6600;
}

.page-promos .promo-card:nth-child(2) {
    margin-left: 15px;
}

.page-promos .sub-title {
    color: rgb(202, 202, 202);
    font-size: 13px;

}

.promo-card .title {
    color: #FF6600;
    font-size: 17px;
}

/* .doc-title:target::before {
        content: "";
        display: block;
        height: 200px;
        margin-top: -200px;
    }*/

.indexes-container li {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

[id]::before {
    content: '';
    display: block;
    height: 60px;
    margin-top: -60px;
    visibility: hidden;
}

.alc-index a {
    color: #d6d6d6e2;
    text-decoration: none;
}

.alc-index a:hover {
    color: #FFF;
}

.doc-anchor {
    color: #FF6600;
}

.doc-action-bar {
    display: none;
}

.doc-body a {
    color: #FF6600;
    text-decoration: none;
}

.doc-body a:hover {
    text-decoration: underline;
}

.doc-title {
    color: #d6d6d6b3;
    font-weight: 500;
}

.doc-body li {
    list-style: disc;
    margin-top: 10px;
    margin-bottom: 10px;
}

.show-drawer {
    display: block;
}

.doc-body hr {
    border-color: #c0c0c041;
}

.doc-body {
    padding: 30px;
    margin-left: 210px;
    border-left: 1px solid rgba(195, 195, 195, 0.21);
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: .2px;
}

.content-index {
    width: 150px;
    padding: 30px;
    /* border-right: 1px solid rgba(195, 195, 195, 0.21); */
    height: calc(100vh - 60px - 30px);
    position: fixed;
}

@media only screen and (max-width: 600px) {
    .doc-root {
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: column;
    }

    .content-index {
        background-color: #353D73;
        box-shadow: 0px 0px 6px 3px #00000033;
        border-radius: 5px;
        width: 60vw;
        z-index: 100;
        margin-top: -60px;
        height: 100vh;
    }

    @keyframes slide-out {
        0% {
            transform: translateX(-60vw);
            opacity: 0.8;
        }

        100% {
            transform: translateX(0px);
            opacity: 1;

        }
    }

    @keyframes slide-in {
        0% {
            transform: translateX(0px);
            opacity: 1;
        }

        100% {
            transform: translateX(-60vw);
            opacity: 0;
        }
    }

    .drawer-anim {
        animation: slide-out 400ms ease;
    }

    .page-promos {
        flex-direction: column;
    }

    .page-promos .promo-card:nth-child(2) {
        margin-left: 0px;
        margin-top: 15px;
    }

    .hide-drawer-anim {
        animation: slide-in 400ms ease;
    }

    .show-drawer {
        display: none;
    }

    .doc-body {
        margin-left: 0;
        padding-top: 0px;
        border-left: none;
    }

    .scroll-top {
        margin-left: auto;
        align-self: center;
        font-size: 12px;
        color: #d6d6d6b3;
        text-decoration: none;
    }

    .nav-action {
        display: flex;
        color: #d6d6d6b3;
        font-size: 12px;
    }

    .nav-action:active {
        color: #FFF;
    }

    .nav-action-text {
        align-self: center;
        margin-left: 5px;
    }

    .doc-action-bar {
        display: flex;
        top: 0px;
        position: sticky;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 30px;
        padding-left: 30px;
        background-color: #353D73;
    }
}</style>