<template>
  <div class="donation-root">
    <span class="donation-title">Support the developer</span>
    <div class="price-row">
        <span class="price" :class="selected[0]" @click="priceClicked(0)">${{ prices[0] }}</span>
        <span class="price" :class="selected[1]" @click="priceClicked(1)">${{ prices[1] }}</span>
        <span class="price" :class="selected[2]" @click="priceClicked(2)">${{ prices[2] }}</span>
    </div>

    <button class="donate-button">Donate ${{ price }} </button>
  </div>
</template>

<script>
export default {
    data(){
        return {
            prices: [5, 10, 20],
            selected: ["", "selected", ""],
            selectedPrice: 10,
        }
    },
    methods: {
        priceClicked(index){
            this.selected = [];
            this.selected[index] = "selected";
            this.selectedPrice = this.prices[index]
        }
    },
    computed: {
        price(){
            return this.selectedPrice;
        }
    }
}
</script>

<style>

    .donation-root {
        display: flex;
        flex-direction: column;
        min-height: 200px;
        justify-content: space-between;
        box-shadow: 0px 0px 6px 3px #00000033;
    }

    .price-row {
        display: flex;
        justify-content: space-between;
    }

    .donation-title {
        font-size: 20px;
        font-weight: normal;
    }

    .price {
        font-size: 20px;
        border-radius: 4px;
        padding: 10px;
        background-color: #1F2444;
        border: 2px solid #5C86F4;
        color: #5C86F4;
        cursor: pointer;
        transition: all 400ms ease;
    }

    .price:hover {
        color: #fff;
    }

    .donate-button {
        height: 40px;
        border-radius: 5px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        cursor: pointer;
        color: #ffffff;
        font-weight: 550;
        background-color: #05B842;
        border: 1px solid #1F2444;
        transition: all 200ms ease;
    }

    .price.selected {
        color: #ffffff;
        background-color: #ff660038;
        border-color: #ff6600;
                
    }

</style>