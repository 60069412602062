<template>
  <dialogframe-vue >
      <div class="dialog-content">
        <span class="dialog-text"> Share Alc </span>

        <div v-if="isLoading" class="share-loading-layout">
            <span class="loading-text">Generating Share Link...</span>
            <hollow-dots-spinner class="loading-spinner" :animation-duration="1000" :dot-size="10" :dots-num="4" color="#ff6600"/>
        </div>

        <input id="sharelink-input" v-else v-model="shareLink" class="share-input" disabled placeholder="share link">

      <div class="dialog-buttons">
            <span class="copied-text" v-if="showCopied" ><b>Copied!</b></span>
            <button class="dialog-button copy-button" @click="copyToClipboard()">Copy</button>
            <button class="dialog-button" @click="closeDialog()">Close</button>
      </div>
      </div>
  </dialogframe-vue>
</template>

<script lang="js">
import dialogframeVue from "./dialogframe.vue";
import { HollowDotsSpinner } from 'epic-spinners';
import { mapActions, mapGetters, mapState } from "vuex";
import { makeid } from '../local';

import "../assets/dialog.css";

export default {
    data(){
        return {
            isLoading: false,
            shareLink: "",
            alc: null,
            showCopied: false
        }
    },
    mounted(){
        this.alc = this.getAlc(this.alcIndexToShare);

        if(this.alc && this.alc.shareId){ // reuse old share link
            // console.log("reusing old sharelink..");
            this.shareLink = `${location.host}/${this.alc.shareId}`;
        } else if (this.builtInNameToShare){
            let { protocol, host } = location;
            this.shareLink = `${protocol}//${host}/${this.builtInNameToShare}`;
        } else {
            this.doGenerateShareLink();
        }

    },
    methods: {
        ...mapActions([
            "generateShareLink",
            "saveToFirestore"
        ]),
        async doGenerateShareLink(){
            this.isLoading = true;

            var id = makeid(6);

            this.alc.shareId = id;
            await this.saveToFirestore(this.alc);

            this.shareLink = `${location.host}/${id}`;
            this.isLoading = false;

            /* this.generateShareLink(this.alc).then((response) => {
                

                // save alc with share link
                this.$store.commit("updateAlc", response.data.data.alc, this.alcIndexToShare);
                // show generated link
                
            }).catch((err) => {
                this.isLoading = false;
                console.error(err);
            })*/
        },
        closeDialog(){
            this.$store.commit("putShowShareAlcDialog", false);
            this.$store.commit("putBuiltInNameToShare", "");
        },
        copyToClipboard(){
            // Copy the text inside the text field
            navigator.clipboard.writeText(this.shareLink);
            this.showCopied = true;

            setTimeout(()=> this.showCopied = false, 1000);

            // alert("Copied the text: " + copyText.value);
        }
    },
    computed: {
        ...mapGetters([
            "getAlc"
        ]),
        ...mapState([
            "alcIndexToShare",
            "builtInNameToShare"
        ])
    },
    components: {
        dialogframeVue,
        HollowDotsSpinner
    }
}
</script>

<style>
    .share-dialog-root {
        display: flex;
    }

    .share-input {
        margin-top: 15px;
        border-radius: 4px;
        font-family: 'Open Sans';
        background-color: #101324;
        border-color: #1F2444;
        padding: 10px;
        color: #d6d6d6;
        border-style: none;
    }

    .share-loading-layout {
        margin: auto;
        margin-top: 30px;
        flex: 1;
    }

    .loading-spinner {
        margin: auto;
        margin-top: 15px;
    }

    .loading-text {
        color: #ABBEF0;
        font-size: 13px;
        font-weight: 550;
    }

    .copied-text {
        color: green;
    }

    .copy-button, .copied-text {
        margin-right: 15px;
    }

</style>