import firebase from 'firebase/app'
import 'firebase/firestore'

// Get a Firestore instance
export const db = firebase.initializeApp({
    apiKey: "AIzaSyBMbY70ZU5Y6oOVjmlulhJYaJG4gM6gM74",
    authDomain: "alc-manager.firebaseapp.com",
    projectId: "alc-manager",
    storageBucket: "alc-manager.appspot.com",
    messagingSenderId: "578449004354",
    appId: "1:578449004354:web:4ceed58b8978d2d6f59adb",
    measurementId: "G-TS19PBS8SP"
}).firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })