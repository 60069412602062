<template>
    <div class="promotion-root">
        <div class="promotion-container">
            <div class="promotion-text">
                <span class="text-big">
                    Save, Update and share your alc setting.
                </span>
                <span class="text-small">
                    Fast, reliable and easy access to your favorite alc settings.
                </span>

                <div class="funnel-buttons">
                    <button class="go-add-alc-button promo-button" @click="goAddAlc()"><i class="plus-icon button-icon"
                            data-feather="plus"></i>Add ALC</button>
                    <button class="go-documentation-button promo-button" @click="goToDoc()" :class="showButtonClass"><i
                            class="doc-icon button-icon" data-feather="book-open"></i>ALC Guide</button>
                </div>

            </div>

            <transition appear name="slide-fade">
                <div class="promotion-image">
                    <img src="../assets/Promotion Images.png" />
                </div>
            </transition>

        </div>

    </div>
</template>

<script>
import feather from 'feather-icons';
import "../assets/alcview.css";
import { setPageDescription } from '../local';

export default {
    data() {
        return {
            showButtonClass: ""
        }
    },
    mounted() {
        feather.replace();
        this.showButtonDelay();

        document.title = "Apex Legends ALC Manager Home"
        const description = "Welcome to the home of Apex Legends ALC settings";

        setPageDescription(document, description);

        this.$gtag.pageview("/");
    },
    methods: {
        goAddAlc() {
            this.$router.push({ path: "/addalc" });
        },
        goToDoc() {
            this.$router.push({ path: "/guide" });
        },
        showButtonDelay() {
            setTimeout(() => {
                this.showButtonClass = "show-button";
            }, 100)
        },
    }
}
</script>

<style>
.promotion-root:before {
    background-image: url('../assets/992033.jpg');
    background-repeat: repeat;
    background-position: 50% 0;
    background-size: cover;
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}

.slide-fade-enter-active {
    transition: all .2s ease;
}

.slide-fade-leave-active {
    transition: all .2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(50px);
    opacity: 0;
}



.promotion-container {
    display: flex;
    padding-left: 300px;
    padding-right: 300px;
    margin-top: 150px;
    position: relative;
}

.promotion-image img {
    height: auto;
    width: 100%;
}

.promotion-text {
    margin-right: 60px;
    display: flex;
    flex-direction: column;
}

.text-big {
    font-size: 28px;
    color: #fff;
}

.text-small {
    font-size: 20px;
    margin-top: 15px;
    color: #C4C4C4;
    font-weight: 350;
}

.funnel-buttons {
    margin-top: 30px;
}

.promo-button {
    border-radius: 5px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    cursor: pointer;
    color: #C6C6C6;
    font-weight: 500;
    background-color: #2D3465;
    border: 1px solid #1F2444;
    transition: all 200ms ease;
}

.promo-button:hover {
    transform: translateY(-2px);
    border-color: #FF6600;
}

.promo-button:active {
    transform: scale(0.8);
}

.go-documentation-button {
    margin-left: 30px;
    visibility: hidden;
}

.show-button {
    animation: zoomIn 200ms ease;
    visibility: visible;
}

.promo-button .button-icon {
    margin-bottom: -3px;
    stroke-width: 3px;
    height: 14px;
    width: 14px;
    margin-right: 8px;
    margin-bottom: -2px;
}

.go-add-alc-button {
    animation: zoomIn 200ms ease;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }

    100% {
        opacity: 100;
        transform: scale(1.0);
    }
}

.plus-icon {
    color: #05B842;
}

.doc-icon {
    color: #FF6600;
}

@media only screen and (max-width: 600px) {
    .promotion-container {
        padding: 30px;
        margin-top: 90px;
        flex-direction: column;
    }

    .promotion-text {
        margin: auto;
    }

    .funnel-buttons {
        display: flex;
        flex-direction: column;
    }

    .go-documentation-button {
        margin-left: 0px;
        margin-top: 15px;
    }

    .promo-button {
        height: 45px;
        font-size: 16px;
    }

    .promotion-image {
        margin-top: 30px;
    }

    .promo-button .button-icon {
        height: 18px;
        width: 18px;
        margin-bottom: -3px;
    }
}</style>