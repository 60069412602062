<template>
    <div class="list-root">
        <div class="tool-bar">
            <div class="dropdown-search-container">
                <dropdown :options="['Name', 'Date Added']" :default="'Date Added'" @item-clicked="sortOptionClicked"
                    class="sort-dropdown"></dropdown>
                <input class="search-input" type="text" placeholder="Filter" v-model="search">
            </div>
            <button class="add-button" @click="showAddAlc()"><i data-feather="plus" class="plus-icon"></i> ADD NEW</button>
        </div>

        <transition-group name="list-add" tag="div" class="list-container" v-on:before-enter="beforeEnter"
            v-on:enter="enter" v-on:leave="leave" appear>
            <alcitem v-for="(alc, index) in _alcs" :data-index="index" :key="alc.title" v-on:alc-clicked="viewAlc(index)"
                class="list-item" :alcobject="alc"></alcitem>
        </transition-group>

    </div>
</template>

<script>
import alcitem from "./alcitem.vue";
import mock from "../static";
import feather from "feather-icons";
import Fuse from 'fuse.js';
import { mapState } from "vuex";
import dropdown from "./dropdown.vue";
import gsap from 'gsap';
import { setPageDescription } from "../local";


export default {
    data() {
        return {
            alcObj: mock.alc,
            search: "",
            filteredalclist: []
        }
    },
    mounted() {
        feather.replace();

        this.$gtag.pageview("/alcs");

        document.title = "List of Apex ALCs";
        const description = "A list of saved Apex Legend ALC settings";

        setPageDescription(document, description);

        // this.$store.commit("addAlc", mock.alc);
    },
    methods: {
        showAddAlc() {
            this.$router.push({ path: "/addalc" });
        },
        viewAlc(index) {
            console.log("viewing alc");
            this.$router.push({ path: "/alc/" + index });
        },
        sortOptionClicked(sortOption) {

            if (sortOption === 'Name') { // sort based on the title of the alc
                this.$store.state.alcs.sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1;
                    }
                    if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            } else if (sortOption === "Date Added") {
                this.$store.state.alcs.sort((a, b) => {
                    if (a.timeadded > b.timeadded) {
                        return -1;
                    }

                    if (a.timeadded < b.timeadded) {
                        return 1;
                    }

                    return 0;
                });
            }
        },
        beforeEnter(el) {
            el.style.translate = "0px 60px";
            el.style.visibility = "hidden";
        },
        enter(el, done) {
            // console.log(el.dataset.index);

            let delay = el.dataset.index * 100;

            setTimeout(() => {
                gsap.to(el, {
                    opacity: 1,
                    translateY: "0px",
                    duration: 0.4,
                    onComplete: done,
                    ease: "power2.easeOut",
                    visibility: "visible"
                })
            }, delay);
        },
        leave(el, done) {
            let delay = el.dataset.index * 100;

            setTimeout(() => {
                gsap.to(el, {
                    opacity: 0.5,
                    translateY: "60px",
                    onComplete: done,
                    duration: 0.4,
                    ease: "power2.easeOut",
                    visibility: "hidden"
                })
            }, delay);
        }

    },
    components: {
        alcitem,
        dropdown
    },
    watch: {
        search() {
            // console.log(this.search);
            const options = {
                includeScore: true,
                keys: ["title"]
            }

            const fuse = new Fuse(this.$store.state.alcs, options);
            const result = fuse.search(this.search);

            this.filteredalclist = result.map(x => {
                return x.item
            })

        }
    },
    computed: {
        _alcs() {
            if (this.filteredalclist.length > 0) {
                return this.filteredalclist
            }

            return this.alcs;
        },
        ...mapState([
            "alcs"
        ]),
    }
}
</script>

<style>
.list-root {
    padding-top: 30px;
    margin-left: 300px;
    margin-right: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 30px;
}

.dropdown-search-container {
    display: flex;
    flex: 1;
}

.search-input {
    height: 32px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 15px;
    padding-right: 10px;
    color: #fff;
    flex: 0.5;
    background-color: #4E649A;
    /*background: url('../assets/filter 1.png') no-repeat left;*/
}

.search-input:focus-visible {
    outline: 1px #FF6600 solid;
}

.search-input::placeholder {
    color: #ABBEF0;
    font-size: 12px;
}

.search-input:hover {
    background-color: #536ba7;
}

.add-button {
    margin-left: auto;
    border: 1px solid #1F2444;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    height: 35px;
    font-weight: 500;
    margin-bottom: 24px;
    background: #2D3465;
    color: #C6C6C6;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.add-button:hover {
    transform: scale(1.02);
    border-color: #FF6600;
    border-width: 1px;
    transform: translateY(-2px);
    color: #ffffff;
}

.add-button:active {
    transform: scale(0.8);
}

.plus-icon {
    height: 16px;
    width: 16px;
    color: #05B842;
    margin-bottom: -3px;
    stroke-width: 3px;
}

/* .list-add-enter-active {
        animation: list-anim 300ms ease;
    }

    .list-add-leave-active {
        animation: list-anim 300ms ease reverse;
    }

    .list-add-move {
        transition: transform 300ms ease;
    }

    @keyframes list-anim {
        0% {
            transform: translateY(20px);
            opacity: 0;
        }

        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }*/


.list-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tool-bar {
    display: flex;
}

.list-item {
    margin-top: 15px;
}

ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0em;
}

@media only screen and (max-width: 600px) {

    .list-root {
        margin-left: 30px;
        margin-right: 30px;
    }

    .list-container {
        justify-content: center;
    }

    .tool-bar {
        flex-direction: column-reverse;
    }

    .search-input {
        height: 32px;
        flex: 1;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .sort-dropdown {
        margin-right: 0px;
    }

    .search-input::placeholder {
        font-size: 13px;
    }

    .add-button {
        margin-bottom: 15px;
    }
}
</style>