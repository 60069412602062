<template>
    <div class="popular-root">
        <h1 class="popular-title">ALCs used by top controller players</h1>

        <ul class="player-list">
            <li v-for="player in popular" :key="player.name" class="player">
                <a :href="player.internalname.toLowerCase()" class="player-link">
                    <span class="player-details"><span class="player-name">{{ player.name }} </span> <span
                            class="player-platform">{{ getPlatform(player) }}</span>
                        <span class="player-alc-state" v-if="isNew(player)"> NEW </span>
                    </span>
                    <span v-if="player.profile" class="player-profile">{{ player.profile }}</span>
                    <a class="twitch-channel" :href="player.channel"> <i data-feather="twitch" class="twitch-icon"></i>{{
                        player.name }}</a>
                    <span class="timeupdated"> last updated {{ getTimeCreated(player) }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
import feather from 'feather-icons';
// eslint-disable-next-line no-unused-vars
import { defaultALCs, setPageDescription } from '../local';
import inbuilts from "../static";

export default {
    data() {
        return {
            popular: inbuilts.popular
        }
    },
    mounted() {
        document.title = "Popular Apex Legends ALC Settings / Popular Apex ALC Settings";

        const description = "This contains a list of Apex Legends ALC settings used by popular players or streamers.";
        setPageDescription(document, description);

        feather.replace();
    },
    methods: {
        getFromNow(utctimestamp) {
            return moment.unix(utctimestamp).fromNow()
        },
        getAlc(player) {
            let alc = defaultALCs.filter((x) => x.title === player.internalname);
            return alc[0]
        },
        getPlatform(player) {
            let plat = this.getAlc(player).platform;

            if (plat === "Playstation") {
                plat = "PS5";
            }

            return plat
        },
        getTimeCreated(player) {
            return this.getFromNow(this.getAlc(player).timeadded);
        },
        isNew(player) {
            let timeadded = this.getAlc(player).timeadded;
            let curTime = Math.floor((new Date()).getTime() / 1000);

            return (curTime - timeadded) < (3 * 24 * 60 * 60)
        }
    }
}
</script>

<style>
.popular-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D6D6D6;
    font-family: 'Inter';
}

.player-profile {
    font-size: 12px;
    color: #cacaca99;
    margin-top: 3px;
    font-weight: 500;
}

.player-list {
    padding: 30px;
    background-color: #1F2444;
    min-height: 100vh;
    margin-bottom: 30px;
    border-radius: 8px;
    min-width: 24vw;
    box-shadow: 2px 2px 17px 6px #00000054;
}

.player-platform {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #5c87f476;
    color: #9BB1EA;
    margin-left: 5px;
    font-weight: 500;
}

.player-alc-state {
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #05b84126;
    color: #05B842;
    font-weight: 500;
}

.player-details {
    display: flex;
    align-items: center;
}

.player-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.player {
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    background: conic-gradient(from 140deg at 50% 50%, rgba(5, 184, 66, 0.18) 141deg, rgba(255, 102, 0, 0.29) 248deg, rgba(5, 184, 66, 0.18) 133deg, rgba(255, 102, 0, 0.29) 4deg);
}

.player:hover {
    outline: 2px solid #ff6600;
}

.player:nth-child(n+2) {
    margin-top: 30px;
}

.player-name {
    font-size: 20px;
    font-weight: 520;
    color: #D6D6D6;
}

.twitch-icon {
    height: 12px;
    width: 12px;
    margin-right: 5px;
}

.twitch-channel {
    font-size: 12px;
    background-color: #9146FF;
    border-radius: 4px;
    max-width: max-content;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    color: white;
    margin-top: 15px;
    text-decoration: none;
}

.twitch-channel:hover {
    text-decoration: underline;
}

.timeupdated {
    margin-top: 15px;
    font-size: 12px;
    color: #a6a6a6;
}

.popular-title {}

@media only screen and (max-width: 600px) {
    .player-list {
        min-width: -webkit-fill-available;
        min-width: -moz-available;
        border-radius: 0px;
    }

    .popular-title {
        margin-left: 30px;
        margin-right: 30px;
    }
}
</style>