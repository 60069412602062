<template>
  <div class="menu-root">
    <ul class="always-visible" >
        <li v-for="option in options" :key="option.name" > 
            <router-link class="nav-link" :to="option.path"> {{ option.name }} </router-link> </li>
    </ul>

    <div class="visible-on-mobile">
        <div @click="showMenu = !showMenu"><i class="menu-icon" data-feather="menu" ></i> </div>

        <transition name="slide-out">
            <ul class="menu-dropdown" v-if="showMenu" v-on-clickaway="clickedOutside">
                <li v-for="option in options" :key="option.name" class="menu-dropdown-item" @click.stop="clickedMenuItem()"> 
                    <router-link class="nav-link" :to="option.path"> {{ option.name }} </router-link>
                </li>
            </ul>
        </transition>

    </div>
    
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [ clickaway ],
    props: ["options"],
    data(){
        return {
            showMenu: false
        }
    },
    methods: {
        clickedOutside(){
            this.showMenu = false;
        },
        clickedMenuItem(){
            this.showMenu = false;
        }
    }
}
</script>

<style>
    .menu-dropdown {
        position: fixed;
        z-index: 200;
        background-color: #2D3465;
        margin-top: 30px;
        border-radius: 5px;
        border: 1px solid #1F2444;
        box-shadow: 0px 0px 8px 0px #00000073;
        margin-left: -120px;
        width: max-content;
    }

    .visible-on-mobile {
        display: none;
    }

    .always-visible {
        display: flex;
    }

    .menu-dropdown-item {
        color: rgb(238, 238, 238);
        font-weight: 520;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;
        font-size: 14px;
    }

    .slide-out-enter-active, .slide-out-leave-active {
        transition: all .2s ease;
    }

    .slide-out-enter, .slide-out-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

    .nav-link {
        align-self: center;
        font-size: 12px;
        font-weight: 550;
        text-decoration: none;
        color: #d6d6d6a5;
    }

    .always-visible .nav-link:nth-child(n+1) {
        margin-left: 30px;
    }

    .nav-link.support-dev {
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
    }

    .nav-link.guide {
        margin-left: 30px;
    }

    @media only screen and (max-width: 600px) {
        .always-visible {
            display: none;
        }

        .visible-on-mobile {
            display: flex;
        }
    }
</style>