<template>
    <transition name="slide-out" appear>
        <donation-vue class="donation-root"></donation-vue>
    </transition>
    
</template>

<script>
import donationVue from './donation.vue';
export default {
    components: {
        donationVue
    }
}
</script>

<style lang="css" scoped>
    .donation-root {
        background-color: #2D3465;
        position: fixed;
        padding: 30px;
        border-radius: 8px;
        margin-top: 30px;
    }

    .slide-out-enter-active, .slide-out-leave-active {
        transition: all .2s ease;
    }

    .slide-out-enter, .slide-out-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

</style>