<template>
<transition
    name="slide-fade"
    v-on:enter="doAnimateStar"
    v-bind:css="false"
    mode="out-in"
>
    <div class="alc-card" @click="doClick">
        <div class="card-alc-title">{{ alcobject.title }}</div>

        <div class="card-alc-container">
            <div class="card-hipfire">
                <span class="card-sens-label">Hipfire</span>
                <div class="card-yaw-root">
                    
                    <div class="card-yaw">
                        <div class="card-alc-value">{{ alcobject.yaw.value }}</div>
                        <div class="card-alc-name">{{ alcobject.yaw.name }}</div>
                    </div>

                    <vue-slider :style="'width: 100%'" v-model="alcobject.yaw.value" :class="hideDot" :clickable="false" :max="500" :height="2"></vue-slider>
                </div>

                <div class="card-pitch-root">
                    <div class="card-pitch">
                        <div class="card-alc-value">{{ alcobject.pitch.value }}</div>
                        <div class="card-alc-name">{{ alcobject.pitch.name }}</div>
                    </div>

                    <vue-slider :style="'width: 100%'" v-model="alcobject.pitch.value" :class="hideDot" :clickable="false" :max="500" :height="2"></vue-slider>
                </div>
            </div>

            <div class="divider"></div>

            <div class="card-ads">
                <span class="card-sens-label">ADS</span>
                <div class="card-adsyaw-root">
                    <div class="card-adsyaw">
                        <div class="card-alc-value">{{ alcobject.adsyaw.value }}</div>
                        <div class="card-alc-name">Yaw</div>
                    </div>
                    
                    <vue-slider :style="'width: 100%'" v-model="alcobject.adsyaw.value" :class="hideDot" :clickable="false" :height="2" :max="500"></vue-slider>
                </div>

                <div class="card-adspitch-root">
                    <div class="card-adspitch">
                        <div class="card-alc-value">{{ alcobject.adspitch.value }}</div>
                        <div class="card-alc-name">Pitch</div>
                    </div>

                <vue-slider :style="'width: 100%'" v-model="alcobject.adspitch.value" :clickable="false" :height="2" :class="hideDot" :max="500"></vue-slider>
                </div>
            </div>
        </div>

        <div class="card-bottom-row">
            <div class="card-date-created">
                <i class="date-created-icon card-icon" data-feather="calendar"></i><span class="date-created-text">{{ getDate() }}</span>
            </div>

            <div class="card-tension">
                <i data-feather="activity" class="tension-icon card-icon"></i><span class="tension-text">{{ alcobject.tension }}</span>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
import VueSlider from "vue-slider-component";
import feather from 'feather-icons';
import moment from 'moment';
import Bounce from "bounce";

export default {
    props: ["alcobject"],
    data(){
        return {
            hideDot: "hide-dot"
        }
    },
    mounted(){
        feather.replace();
    },
    methods: {
        getDate(){
            return moment.unix(this.alcobject.timeadded).fromNow();
        },
        doAnimateStar: function(el, done){
            console.log("called");
            var bounce = new Bounce();
            bounce.scale({
                from: { x: 0.2, y: 0.2 },
                to: {x: 1, y: 1},
                duration: 700,
                bounces: 3,
                stiffness: 1
            })

            bounce.applyTo(el);
            done();
        },
        doClick(){
            this.$emit("alc-clicked");
        }
    },
    components: {
        VueSlider
    }
}
</script>

<style>
    .alc-card {
        border: 0px solid #1F2444;
        border-radius: 10px;
        padding: 30px;
        color: #D6D6D6;
        display: flex;
        flex-direction: column;
        flex: 0.28;
        min-height: 300px;
        background-color: #2D3465;
        transition: all 0.2s ease;
        cursor: pointer;
        min-width: 200px;
        max-width: 200px;
        border: 1px #212646 solid;
        background-image: url('../assets/gaming.svg');
        background-size: cover;
        box-shadow: 0px 2px 0px 1px #00000017;
    }

    .card-sens-label {
        font-size: 13px;
        margin-bottom: 10px;
        font-weight: 600;
        color: #7E7E7E;
    }

    .alc-card:hover {
        border-color: #FF6600;
        border-width: 1px;
        /*transform: scale(1.02);*/
        transform: translateY(-10px);
        /*box-shadow: 0px 0px 8px 0px #00000073;*/
    }

    .alc-card:active {
        transform: scale(0.8);
    }

    .card-alc-title {
        font-weight: 600;
    }

    .card-alc-container {
        display: flex;
        margin-top: auto;
    }

    .card-ads {
        display: flex;
        flex-direction: column;
        margin-left: auto;
    }

    .card-hipfire {
        display: flex;
        flex-direction: column;
    }

    .card-bottom-row {
        display: flex;
        margin-top: auto;
    }

    .card-tension {
        margin-left: auto;
    }

    .card-alc-value {
        font-size: 26px;
        font-weight: 300;
        color: #d6d6d6d9;
    }

    .card-alc-name {
        font-size: 12px;
        margin-left: 5px;
        margin-top: 17px;
        color: #b4b4b498;
    }

    .card-pitch-root {
        display: flex;
        flex-direction: column;
        margin-left: auto;       
    }

    .card-pitch {
        display: flex;
        margin-left: auto;
    }

    .card-adspitch-root {
        display: flex;
        margin-left: auto;
        flex-direction: column;
    }

    .card-adspitch{
        display: flex;
        margin-left: auto;
    }

    .card-adsyaw-root {
        display: flex;
        flex-direction: column;
    }

    .divider {
        border-left: 1px solid #abbef025;
        margin-left: auto;
    }

    .card-adsyaw {
        display: flex;
    }

    .card-yaw {
        display: flex;
    }

    .card-yaw-root{
        display: flex;
        flex-direction: column;
    }

    .card-date-created {
        font-size: 12px;
    }

    .card-icon {
        height: 14px;
        width: 14px;
        margin-bottom: -2px;
    }

    .date-created-icon {
        color: #c2c2c270;
    }

    .date-created-text {
        margin-left: 5px;
        color: #c2c2c2a4;
    }

    .card-tension {
        font-size: 12px;
    }

    .tension-text {
        color: #5C86F4;
        margin-left: 5px;
    }

    .tension-icon {
        color: #5c87f498;
    }
    .vue-slider-rail {
        background-color: #1F2444;
    }

    .vue-slider-process {
        background-color: #FF6600;
    }

    .hide-dot .vue-slider-dot {
        display: none !important;
    }

    .slide-fade-leave-active {
        transition: all .5s ease;
    }

    .slide-fade-leave
    /* .slide-fade-leave-active below version 2.1.8 */ {
    /* opacity: 0; */
        transform: scale(0.5);
    }

    @media only screen and (max-width: 600px) {
        .alc-card {
            /* min-height: 212px; */
            min-width: calc(100% - 60px);
            padding: 30px;
            flex: 1;
            min-height: 400px;
        }

        .card-alc-title {
            font-size: 20px;
        }

        .card-alc-value {
            font-size: 35px;
        }

        .card-date-created, .card-tension {
            font-size: 13px;
        }

        .alc-card:hover {
            transform: none;
        }
    }
</style>