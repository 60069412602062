import Vue from 'vue';
import vuex from 'vuex';
import vuexpersist from 'vuex-persist';
import axios from 'axios';
import { backendSession } from '../local';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import { db } from '../db';

Vue.use(vuex);

const vuexLocalStorage = new vuexpersist({
    key: 'alcstorage',
    modules: ['alcs', 'alcCount'],
    filter: (mutation) => {
        var blockedMutations = [
            "putShowDeleteAlcDialog",
            "putAlcIndexToDelete"
        ];

        return blockedMutations.indexOf(mutation.type) == -1;
    },
    storage: window.localStorage
})

export default new vuex.Store( {
    state: {
        alcs: [],
        sharedAlcs: [],
        showDeleteAlcDialog: false,
        showShareAlcDialog: false,
        builtInNameToShare: "",
        alcIndexToDelete: 0,
        alcIndexToShare: 0,
        alcCount: 0,
        session: backendSession
    },
    actions: {
        bindAlcs: firestoreAction( ({ bindFirestoreRef }) => {
            return bindFirestoreRef('sharedAlcs', db.collection('alcs'));
        }),
        saveToFirestore: firestoreAction((ctx, alc) => {
            return db.collection("alcs").doc(alc.shareId).set( { ...alc });
        }),
        getFromFirestore: firestoreAction((ctx, id) => {
            return db.collection("alcs").doc(id).get();
        }),
        generateShareLink(store, alc){
            return axios({
                method: 'post',
                url: "http://127.0.0.1:9000" + "/document/alcs",
                headers: {
                    "X-BB-SESSION": store.state.session
                },
                data: {
                    alc: alc
                }
            })
        },
        loadAlcFromID(store, id){
            // console.log("loading alc...")
            return axios({
                method: 'get',
                url: "http://127.0.0.1:9000" + "/document/alcs",
                headers: {
                    "X-BB-SESSION": store.state.session
                },
                params: {
                    where: "alc.shareId=?",
                    params: id  
                }
            })
        }
    },
    mutations: {
        
        addAlc: function(state, alc){
            // console.log("adding new alc");
            //insert new alc to the start of the list
            state.alcs.splice(0, 0, alc);
            // state.alcs.push(alc);
        },
        updateAlc: function(state, alc, index){
            // console.log("updating..")
            state.alcs.splice(index, 1, alc);
        },
        putShowDeleteAlcDialog: function(state, value){
            state.showDeleteAlcDialog = value;
        },
        putShowShareAlcDialog: function(state, value){
            state.showShareAlcDialog = value;
        },
        putAlcIndexToShare(state, index){
            state.alcIndexToShare = index;
        },
        putBuiltInNameToShare(state, name){
            state.builtInNameToShare = name;
        },
        putAlcIndexToDelete: function(state, arg){
            state.alcIndexToDelete = arg;
        },
        doDeleteAlc: function(state){
            state.alcs.splice(state.alcIndexToDelete, 1);
        },
        increaseAlcCount: state => {
            state.alcCount += 1;
        },
        ...vuexfireMutations
    },
    getters: {
        getAlc: state => {
            return index => state.alcs[index];
        }
    },
    plugins: [vuexLocalStorage.plugin]
});

