let gaID = "G-FWMLPDDVZF";
let backendSession = "6f614eb4-38e5-4aff-b474-ea1e9b9a00fe";

const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

/** @param { Document } rootElement */
const setPageDescription = (rootElement, description) => {
    let metaElement = rootElement.createElement("meta");
    metaElement.setAttribute("name", "description");
    metaElement.setAttribute("content", description);
    
    const oldNode = Array.from(rootElement.querySelector("head").getElementsByTagName("meta"))
    .filter(( el ) => el.getAttribute("name") === "description");

    // remove any existing description
    if (oldNode.length) rootElement.querySelector("head").removeChild(oldNode[0]);

    rootElement.querySelector("head") .insertAdjacentElement("afterbegin", metaElement);
}

let defaultALCs = [
    {
        "title": "Genburten",
        "deadzone": {
          "name": "Deadzone",
          "value": 1
        },
        "outertreshold": {
          "name": "Outer Treshold",
          "value": 1
        },
        "responsecurve": {
          "name": "Response Curve",
          "value": 0
        },
        "yaw": {
          "name": "Yaw",
          "value": 500
        },
        "pitch": {
          "name": "Pitch",
          "value": 500
        },
        "turningyaw": {
          "name": "Turning Extra Yaw",
          "value": 0
        },
        "turningpitch": {
          "name": "Turning Extra Pitch",
          "value": 0
        },
        "turningrampup": {
          "name": "Turning Ramp-up Time",
          "value": 0
        },
        "turningdelay": {
          "name": "Turning Ramp-up Delay",
          "value": 0
        },
        "adsyaw": {
          "name": "ADS Yaw",
          "value": 130
        },
        "adspitch": {
          "name": "ADS Pitch",
          "value": 130
        },
        "adsturningyaw": {
          "name": "ADS Turning Extra Yaw",
          "value": 0
        },
        "adsturningpitch": {
          "name": "ADS Turning Extra Pitch",
          "value": 0
        },
        "adsturningrampup": {
          "name": "ADS Turning Ramp-up Time",
          "value": 0
        },
        "adsturningdelay": {
          "name": "ADS Turning Ramp-up Delay",
          "value": 0
        },
        "timeadded": 1672300462,
        "tension": "Normal",
        "description": "This setting focuses lot on control and accuracy. \n\nThe lack of usage of any of the Extra options shows a high confidence in the player's mechanical ability to accurately make these adjustments himself.",
        "videolink": "https://twitter.com/i/status/1562774187688984576",
        "platform": "PC",
        "controller": "Battlebeaver"
      },
    {
        "title": "Extessy",
        "deadzone": {
          "name": "Deadzone",
          "value": 3
        },
        "outertreshold": {
          "name": "Outer Treshold",
          "value": 1
        },
        "responsecurve": {
          "name": "Response Curve",
          "value": 0
        },
        "yaw": {
          "name": "Yaw",
          "value": 350
        },
        "pitch": {
          "name": "Pitch",
          "value": 350
        },
        "turningyaw": {
          "name": "Turning Extra Yaw",
          "value": 250
        },
        "turningpitch": {
          "name": "Turning Extra Pitch",
          "value": 250
        },
        "turningrampup": {
          "name": "Turning Ramp-up Time",
          "value": 0
        },
        "turningdelay": {
          "name": "Turning Ramp-up Delay",
          "value": 0
        },
        "adsyaw": {
          "name": "ADS Yaw",
          "value": 130
        },
        "adspitch": {
          "name": "ADS Pitch",
          "value": 130
        },
        "adsturningyaw": {
          "name": "ADS Turning Extra Yaw",
          "value": 0
        },
        "adsturningpitch": {
          "name": "ADS Turning Extra Pitch",
          "value": 0
        },
        "adsturningrampup": {
          "name": "ADS Turning Ramp-up Time",
          "value": 0
        },
        "adsturningdelay": {
          "name": "ADS Turning Ramp-up Delay",
          "value": 0
        },
        "timeadded": 1671873860,
        "tension": "Normal",
        "description": "3 Deadzone to stop stick drift. ",
        "videolink": "",
        "platform": "PC",
        "controller": "Xbox Elite"
    },
    {
        "title": "Termk47",
        "deadzone": {
            "name": "Deadzone",
            "value": 4
        },
        "outertreshold": {
            "name": "Outer Treshold",
            "value": 2
        },
        "responsecurve": {
            "name": "Response Curve",
            "value": 2
        },
        "yaw": {
            "name": "Yaw",
            "value": 400
        },
        "pitch": {
            "name": "Pitch",
            "value": 400
        },
        "turningyaw": {
            "name": "Turning Extra Yaw",
            "value": 0
        },
        "turningpitch": {
            "name": "Turning Extra Pitch",
            "value": 0
        },
        "turningrampup": {
            "name": "Turning Ramp-up Time",
            "value": 0
        },
        "turningdelay": {
            "name": "Turning Ramp-up Delay",
            "value": 0
        },
        "adsyaw": {
            "name": "ADS Yaw",
            "value": 140
        },
        "adspitch": {
            "name": "ADS Pitch",
            "value": 90
        },
        "adsturningyaw": {
            "name": "ADS Turning Extra Yaw",
            "value": 0
        },
        "adsturningpitch": {
            "name": "ADS Turning Extra Pitch",
            "value": 0
        },
        "adsturningrampup": {
            "name": "ADS Turning Ramp-up Time",
            "value": 0
        },
        "adsturningdelay": {
            "name": "ADS Turning Ramp-up Delay",
            "value": 0
        },
        "timeadded": 1671453933,
        "tension": "Normal",
        "description": "",
        "videolink": "",
        "platform": "PC",
        "controller": "Dualshock 4"
    },
    {
        "title": "Scrappy",
        "deadzone": {
            "name": "Deadzone",
            "value": 2
        },
        "outertreshold": {
            "name": "Outer Treshold",
            "value": 1
        },
        "responsecurve": {
            "name": "Response Curve",
            "value": 6
        },
        "yaw": {
            "name": "Yaw",
            "value": 350
        },
        "pitch": {
            "name": "Pitch",
            "value": 350
        },
        "turningyaw": {
            "name": "Turning Extra Yaw",
            "value": 0
        },
        "turningpitch": {
            "name": "Turning Extra Pitch",
            "value": 0
        },
        "turningrampup": {
            "name": "Turning Ramp-up Time",
            "value": 0
        },
        "turningdelay": {
            "name": "Turning Ramp-up Delay",
            "value": 0
        },
        "adsyaw": {
            "name": "ADS Yaw",
            "value": 260
        },
        "adspitch": {
            "name": "ADS Pitch",
            "value": 260
        },
        "adsturningyaw": {
            "name": "ADS Turning Extra Yaw",
            "value": 20
        },
        "adsturningpitch": {
            "name": "ADS Turning Extra Pitch",
            "value": 20
        },
        "adsturningrampup": {
            "name": "ADS Turning Ramp-up Time",
            "value": 0
        },
        "adsturningdelay": {
            "name": "ADS Turning Ramp-up Delay",
            "value": 0
        },
        "timeadded": 1671453584,
        "tension": "Normal",
        "description": "",
        "videolink": "",
        "platform": "PC",
        "controller": "DualSense"
    },
    {
        "title": "vUnlucky",
        "deadzone": {
            "name": "Deadzone",
            "value": 1
        },
        "outertreshold": {
            "name": "Outer Treshold",
            "value": 1
        },
        "responsecurve": {
            "name": "Response Curve",
            "value": 0
        },
        "yaw": {
            "name": "Yaw",
            "value": 400
        },
        "pitch": {
            "name": "Pitch",
            "value": 300
        },
        "turningyaw": {
            "name": "Turning Extra Yaw",
            "value": 0
        },
        "turningpitch": {
            "name": "Turning Extra Pitch",
            "value": 0
        },
        "turningrampup": {
            "name": "Turning Ramp-up Time",
            "value": 0
        },
        "turningdelay": {
            "name": "Turning Ramp-up Delay",
            "value": 0
        },
        "adsyaw": {
            "name": "ADS Yaw",
            "value": 100
        },
        "adspitch": {
            "name": "ADS Pitch",
            "value": 100
        },
        "adsturningyaw": {
            "name": "ADS Turning Extra Yaw",
            "value": 0
        },
        "adsturningpitch": {
            "name": "ADS Turning Extra Pitch",
            "value": 0
        },
        "adsturningrampup": {
            "name": "ADS Turning Ramp-up Time",
            "value": 0
        },
        "adsturningdelay": {
            "name": "ADS Turning Ramp-up Delay",
            "value": 0
        },
        "timeadded": 1671376643,
        "tension": "Normal",
        "description": "The overall sens is slow so it focuses a lot being accurate over movement.",
        "videolink": "",
        "platform": "PC",
        "controller": "Dualshock 4"
    },
    {
        "title": "iGrindApex",
        "deadzone": {
            "name": "Deadzone",
            "value": 3
        },
        "outertreshold": {
            "name": "Outer Treshold",
            "value": 3
        },
        "responsecurve": {
            "name": "Response Curve",
            "value": 2
        },
        "yaw": {
            "name": "Yaw",
            "value": 400
        },
        "pitch": {
            "name": "Pitch",
            "value": 400
        },
        "turningyaw": {
            "name": "Turning Extra Yaw",
            "value": 250
        },
        "turningpitch": {
            "name": "Turning Extra Pitch",
            "value": 250
        },
        "turningrampup": {
            "name": "Turning Ramp-up Time",
            "value": 0
        },
        "turningdelay": {
            "name": "Turning Ramp-up Delay",
            "value": 0
        },
        "adsyaw": {
            "name": "ADS Yaw",
            "value": 150
        },
        "adspitch": {
            "name": "ADS Pitch",
            "value": 150
        },
        "adsturningyaw": {
            "name": "ADS Turning Extra Yaw",
            "value": 0
        },
        "adsturningpitch": {
            "name": "ADS Turning Extra Pitch",
            "value": 0
        },
        "adsturningrampup": {
            "name": "ADS Turning Ramp-up Time",
            "value": 0
        },
        "adsturningdelay": {
            "name": "ADS Turning Ramp-up Delay",
            "value": 0
        },
        "timeadded": 1671368466,
        "tension": "Normal",
        "description": "Has the deadzone set at 4 to make his aim firm or just to counter stick-drift\n\nPlays with low ADS sens and some response curve because of a preference for starting fights at range.\n\nIncreased Outer-threshold, high yaw/pitch speed and maxed-out Extra yaw/Extra Pitch because he prioritizes movement over hipfire. ",
        "videolink": "https://youtu.be/YS7mOa8NZS8",
        "platform": "Playstation",
        "controller": "DualSense"
    }
]
export { gaID, backendSession, defaultALCs, makeid, setPageDescription }