<template>
    <div class="sens-container">
        <div class="action-bar">
            <div class="sens-title" contenteditable="true" @input="handleInput" id="sensTitle" ref="senstitle">{{ alc.title
            }}</div>
            <div class="action-buttons">
                <button class="save-button action-button" @click="save()"> <i class="save-icon button-icon"
                        data-feather="folder-plus"></i> Save </button>
                <button class="cancel-button action-button" @click="cancel()"> <i class="cancel-icon button-icon"
                        data-feather="x"></i> Cancel </button>
                <div class="close-alcview" @click="cancel()"><i class="close-alc-icon" data-feather="x"></i></div>
            </div>
        </div>
        <div class="title-sens-layer">
            <transition appear name="slide-fade" v-on:leave="onTransitionDone">
                <div class="sens-card">
                    <button class="action-button alc-action clear-button" @click="clear()"> Clear </button>
                    <button class="action-button alc-action reset-button" @click="reset()"> Reset to default </button>

                    <alcslider :title="'Deadzone'" :alcmax="50" :interval="2" v-model="alc.deadzone.value"></alcslider>
                    <alcslider :title="'Outer Threshold'" :alcmax="31" v-model="alc.outertreshold.value"></alcslider>
                    <alcslider :title="'Response Curve'" :alcmax="30" v-model="alc.responsecurve.value"></alcslider>

                    <span class="sens-category">Hipfire Settings</span>

                    <alcslider :title="'Yaw Speed'" :alcmax="500" :interval="10" v-model="alc.yaw.value"></alcslider>
                    <alcslider :title="'Pitch Speed'" :alcmax="500" :interval="10" v-model="alc.pitch.value"></alcslider>

                    <alcslider :title="'Turning Extra Pitch'" :alcmax="250" :interval="10" v-model="alc.turningpitch.value">
                    </alcslider>
                    <alcslider :title="'Turning Extra Yaw'" :alcmax="250" :interval="10" v-model="alc.turningyaw.value">
                    </alcslider>

                    <alcslider :title="'Turning Ramp Up Time'" :alcmax="100" :interval="5"
                        v-model="alc.turningrampup.value"></alcslider>
                    <alcslider :title="'Turning Ramp up Delay'" :alcmax="100" :interval="5"
                        v-model="alc.turningdelay.value"></alcslider>

                    <span class="sens-category">ADS Settings</span>

                    <alcslider :title="'ADS Yaw Speed'" :alcmax="500" :interval="10" v-model="alc.adsyaw.value"></alcslider>
                    <alcslider :title="'ADS Pitch Speed'" :alcmax="500" :interval="10" v-model="alc.adspitch.value">
                    </alcslider>

                    <alcslider :title="'ADS Turning Extra Pitch'" :alcmax="250" :interval="10"
                        v-model="alc.adsturningpitch.value"></alcslider>
                    <alcslider :title="'ADS Turning Extra Yaw'" :alcmax="250" :interval="10"
                        v-model="alc.adsturningyaw.value"></alcslider>

                    <alcslider :title="'ADS Turning Ramp Up Time'" :alcmax="100" :interval="5"
                        v-model="alc.adsturningrampup.value"></alcslider>
                    <alcslider :title="'ADS Turning Ramp up Delay'" :alcmax="100" :interval="5"
                        v-model="alc.adsturningdelay.value"></alcslider>
                </div>
            </transition>

            <transition appear>
                <div class="meta-info" :class="hideColumnClass">
                    <div class="tension-label">
                        <sticksvg class="meta-icon icon-park" /> Tension
                    </div>
                    <div class="tension-container">
                        <tension :title="tensions[0]" :enabled="enabledTensions[0]"
                            @tension-clicked="handleTensionClick(0)"></tension>
                        <tension :title="tensions[1]" :enabled="enabledTensions[1]"
                            @tension-clicked="handleTensionClick(1)"></tension>
                        <tension :title="tensions[2]" :enabled="enabledTensions[2]"
                            @tension-clicked="handleTensionClick(2)"></tension>
                    </div>

                    <div class="meta-item">
                        <div class="tension-label">
                            <platformsvg class="meta-icon icon-park"></platformsvg>Platform
                        </div>
                        <vueselect class="platform-dropdown" :placeholder="'Choose Platform'" :options="platforms"
                            v-model="alc.platform"></vueselect>
                    </div>

                    <div class="meta-item">
                        <div class="tension-label">
                            <controllersvg class="meta-icon icon-park"></controllersvg>Controller
                        </div>
                        <vueselect class="controller-dropdown" :placeholder="'Choose Controller'" :options="controllers"
                            v-model="alc.controller">
                        </vueselect>
                    </div>

                    <div class="description meta-item">
                        <div class="tension-label">
                            <descriptionsvg class="meta-icon"></descriptionsvg>Description
                        </div>
                        <textarea class="description-text" type="text" placeholder="ALC Description"
                            v-model="alc.description"></textarea>
                    </div>

                    <div class="video-link meta-item">
                        <span class="tension-label">
                            <videosvg class="meta-icon"></videosvg>Video Link
                        </span>
                        <input class="video-link-input" type="text" placeholder="https://" v-model="alc.videolink">
                    </div>

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import alcslider from './alcslider.vue';
import tension from './tension.vue';
import sticksvg from '../assets/padstick.svg';
import descriptionsvg from '../assets/description.svg';
import videosvg from '../assets/video.svg';
import platformsvg from '../assets/platform.svg';
import controllersvg from '../assets/controller.svg';
import feather from 'feather-icons';
import "../assets/alcview.css";
import 'vue-select/dist/vue-select.css';
import vueselect from 'vue-select';
import mock from "../static";
import { mapState } from 'vuex';
import { setPageDescription } from "../local";
// import Vue from "vue";

export default {
    data() {
        return {
            tempAlc: JSON.parse(JSON.stringify(mock.alc)),
            copy: {},
            title: "Wingman Warrior settings",
            showMetaColumn: false,
            hideColumnClass: "",
            platforms: [
                "PC",
                "Playstation",
                "Xbox"
            ],
            controllers: [
                "Dualshock 4",
                "DualSense",
                "Scuf Impact",
                "Xbox Default",
                "Xbox Elite",
                "Battlebeaver"
            ],
            enabledTensions: [
                "",
                "enabled",
                ""
            ],
            tensions: [
                "Loose",
                "Normal",
                "Stiff"
            ]
        }
    },
    mounted() {
        feather.replace();
        this.showMetaDelay();

        this.tempAlc.title = `Alc Sens#${this.alcCount + 1}`;
        document.title = `Create ${this.alc.title}`;
        const description = "Create and save new Apex ALC Setting for your controller";
        setPageDescription(document, description);

        if (this.$route.params.id) {
            // make a copy of the old alc
            let main = this.$store.getters.getAlc(this.$route.params.id);
            this.copy = JSON.parse(JSON.stringify(main));

            this.$nextTick(() => {
                document.title = `Editing ${this.alc.title}`;
            });
        }

        this.$gtag.pageview("/addalc");

        this.loadTension();
    },
    methods: {
        handleInput(e) {
            //this.title = e.target.InnerHTML;

            const sel = document.getSelection();
            const offset = sel.anchorOffset;
            this.alc.title = e.target.textContent;
            this.$nextTick(() => { sel.collapse(sel.anchorNode, offset); });
        },
        selectAll() {
            var titleObj = document.getElementById("sensTitle");
            titleObj.onfocus = function () {
                window.setTimeout(function () {
                    var sel, range;
                    if (window.getSelection && document.createRange) {
                        range = document.createRange();
                        range.selectNodeContents(titleObj);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    } else if (document.body.createTextRange) {
                        range = document.body.createTextRange();
                        range.moveToElementText(titleObj);
                        range.select();
                    }
                }, 1);
            };
        },
        showMetaDelay() {
            setTimeout(() => {
                this.hideColumnClass = "show-meta-info";
            }, 100)
        },
        cancel() {
            if (this.$route.params.id) {
                this.$store.commit("updateAlc", this.copy, this.$route.params.id);
            }

            this.$router.go(-1);
        },
        onTransitionDone() {
            // console.log("Transition done");
        },
        handleTensionClick(tensionIndex) {
            // console.log("clicked tension");
            this.enabledTensions = ["", "", ""];
            this.enabledTensions[tensionIndex] = "enabled";

            this.alc.tension = this.tensions[tensionIndex];
        },
        loadTension() {
            this.enabledTensions = ["", "", ""];
            this.enabledTensions[this.tensions.indexOf(this.alc.tension)] = "enabled";
        },
        save() {
            if (this.$route.params.id) { // update changes if old alc
                this.$store.commit('updateAlc', this.alc, parseInt(this.$route.params.id));
            } else {
                this.alc.timeadded = Math.floor((new Date()).getTime() / 1000);
                this.$store.commit("addAlc", this.alc);
                this.$store.commit("increaseAlcCount");
            }

            this.$router.go(-1);
        },
        clear() {
            var keys = Object.keys(this.alc);
            keys.forEach((key) => {
                if (typeof this.alc[key] === 'object') {
                    //console.log(key);
                    this.$set(this.alc[key], 'value', 0);
                }
            });
        },
        reset() {
            var keys = Object.keys(this.alc);
            keys.forEach((key) => {
                if (typeof this.alc[key] === 'object') {
                    //console.log(key);

                    this.$set(this.alc[key], 'value', mock.alc[key].value);
                }
            });

        }
    },
    computed: {
        ...mapState([
            "alcCount"
        ]),
        alc() {
            if (this.$route.params.id) {
                // console.log('parameter is ' + this.$route.params.id);
                // make a copy of the alc and return it

                let main = this.$store.getters.getAlc(this.$route.params.id);
                return main;
            } else {
                return this.tempAlc
            }

        }
    },
    components: {
        alcslider,
        tension,
        sticksvg,
        descriptionsvg,
        platformsvg,
        videosvg,
        vueselect,
        controllersvg
    }
}
</script>

<style>
.close-alcview {
    display: none;
}

.cancel-button {
    display: block;
}


:root {
    --vs-controls-color: #afafaf;
    --vs-border-color: #1F2444;
    --vs-colors--dark: #d6d6d6;
    --vs-search-input-placeholder-color: #d6d6d6;

    --vs-colors--lightest: #FF6600;
    --vs-colors--light: #FF6600;
    --vs-colors--darkest: rgba(0, 0, 0, 0.15);
}

.icon-park {
    margin-bottom: -2px !important;
    margin-right: 10px;
}

.platform-dropdown,
.controller-dropdown {
    margin-top: 15px;
}

@media only screen and (max-width: 600px) {
    .cancel-button {
        display: none;
    }

    .close-alcview {
        display: block;
    }

    .description-text,
    .video-link-input {
        font-size: 14px;
    }

    .meta-icon {
        margin-bottom: 0px;
    }

    .meta-item {
        margin-top: 30px;
    }

}
</style>
