<template>
    <div class="not-found-root">
        <i data-feather="alert-octagon" class="not-found-icon"></i>
        <span class="not-found-text"> Page not found</span>  
        <router-link to="/" class="home-link">Go to Homepage</router-link>
    </div>
</template>

<script>
import feather from 'feather-icons';
// import { mapActions } from 'vuex';

export default {
    beforeMount(){
        if(this.$route.params.pathMatch.startsWith("id")){
            this.$router.replace({name: "alcview", params: { shareid: this.$route.params.pathMatch }})
        }
    },
    mounted(){
        feather.replace();
    },
    methods: {

    }
}
</script>

<style>
    .not-found-root {
        height: 80vh;
        width: auto;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        justify-content: center;
        align-items: center;
    }

    .not-found-icon {
        height: 100px;
        width: auto;
        color: #272b4d;
    }

    .not-found-text {
        margin-top: 30px;
        font-size: 30px;
        color: rgb(206, 206, 206);
    }

    .home-link {
        color: #FF6600;
        margin-top: 15px;
        font-weight: 550;
    }
</style>