import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "./store";

import App from './App.vue';
import VueYoutubeEmbed from 'vue-youtube-embed';
import VueGtag from "vue-gtag";
import { gaID, defaultALCs } from "./local"
import VueSmoothScroll from 'vue2-smooth-scroll';
import { firestorePlugin } from 'vuefire';

// import Home from './components/Home';

import alclist from './components/alclist.vue';
import addalc from './components/addalc.vue';
import alcview from './components/alcview.vue';
import promo from './components/promotion.vue';
import notfound from './components/notfound.vue';
import documentation from './components/documentation.vue';
import popularalcs from './components/popularalcs.vue';

import "fontsource-open-sans/latin.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/variable.css";

Vue.config.productionTip = false

const routes = [
    { path: '/', name: "promotion", component: promo },
    { path: '/alcs', name: "alcs", component: alclist },
    { path: '/guide', name: 'guide', component: documentation },
    { path: '/popular', name: 'popular', component: popularalcs },
    { path: '/notfound', name: 'error', component: notfound },
    { path: '/addalc/:id?', name: "addalc", component: addalc },
    { path: '/alc/:id?', name: "alcview", component: alcview },
    { path: '/:pathMatch(.*)*', name: 'notfound', component: notfound }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    const { params: { pathMatch } } = to

    let isInbuilt = false;
    if (pathMatch){
        isInbuilt = defaultALCs.filter((x) => x.title.toLowerCase() === pathMatch.toLowerCase()).length > 0;
    }

    if (to.path == "/" && store.state.alcs.length > 0) {
        next({path: "/alcs"});
    } else if (pathMatch && isInbuilt ) {
        // intercept requests that go to any of the popular alc pages
        next({ path: `/alc`, query: { player: pathMatch } });
    } else if ( pathMatch && !isInbuilt ){
        next({ path: '/alc', query: { shareid: pathMatch }});
    } else {
        next();
    }
})

Vue.use(VueRouter);
Vue.use(VueYoutubeEmbed);

Vue.use(VueSmoothScroll);

Vue.use(VueGtag, {
    config: {
        id: gaID
    }
});

Vue.use(firestorePlugin);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
