<template>
    <dialogframe-vue >
        <div class="delete-alc-root">
            <span class="dialog-text">
                Are you sure you want to delete this alc setting?
            </span>
            <div class="delete-alc-dialog-buttons">
                <button class="dialog-button delete-alc-action" @click="deleteAlc()">Delete</button> 
                <button class="dialog-button cancel-delete-alc-action" @click="hideDeleteAlcDialog()">Cancel</button>
            </div>
            
        </div>
    </dialogframe-vue>
</template>

<script>
import dialogframeVue from "./dialogframe.vue"

export default {
    methods: {
        hideDeleteAlcDialog(){
            this.$store.commit("putShowDeleteAlcDialog", false);
        },
        deleteAlc(){
            this.$store.commit("doDeleteAlc");
            this.hideDeleteAlcDialog();
            
            this.$router.replace({path: "/"});
        }
    },
    components: {
        dialogframeVue
    }
}
</script>

<style>
    .delete-alc-root {
        border-radius: 4px;
        background: #212649;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 6px 3px #00000033;
        border-top-width: 2px;
        border-top-color: #FF6600;
    }

    .delete-alc-dialog-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .dialog-text {
        color: #e5e5e586;
        font-size: 15px;
        font-weight: 600;
    }

    .dialog-button {
        border-radius: 5px;
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        cursor: pointer;
        color: #C6C6C6;
        font-weight: 500;
        background-color: #2D3465;
        border: 1px solid #1F2444;
        transition: all 200ms ease;
    }

    .delete-alc-dialog-buttons .dialog-button:active {
        transform: scale(0.8);
    }

    .dialog-button:hover {
        transform: translateY(-2px);
        border-color: #FF6600;
    }

    .cancel-delete-alc-action {
        margin-left: 15px;
    }

    .delete-alc-action {
        background-color: #850707;
    }

    @media only screen and (max-width: 600px) {
        .delete-alc-root {
            margin-left: 30px;
            margin-right: 30px;
        }
    }

</style>