<template>
    <div class="slider-container">
        <div class="title">{{ title }}</div>

        <div class="slider-val-container">
            <vue-slider class="slider-main" :style="'width: 100%'" v-model="value" :max="alcmax" 
            :clickable="clickable" :class="[hideDot]" :interval="interval" ></vue-slider>
            <span class="slider-value">{{ value }}</span>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
    props: ["title", "alcmax", "value", "interactive", "interval"],
    data(){
        return {
            clickable: true,
            hideDot: ""
        }
    },
    mounted(){
        if(this.interactive === false ){
            this.clickable = false,
            this.hideDot = "hide-dot"
        }
    },
    components: {
        VueSlider
    },
    watch: {
        value(){
            this.$emit('input', this.value);
        }
    }
}
</script>

<style>
    .title {
        color: #d6d6d6b4;
    }

    .slider-container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .slider-val-container {
        display: flex;
    }

    .vue-slider-rail {
        background-color: #1F2444;
    }

    .vue-slider-process {
        background-color: #FF6600;
    }

    .vue-slider-dot-handle {
        border: 3px solid #fff;
        background-color: #FF6600;
    }

    .vue-slider-dot-tooltip-inner {
        background-color: #1F2444;
        border-color: #1F2444;
    }

    .slider-value {
        margin-left: 40px;
        color: #d6d6d69e;
        font-weight: 500;
    }

    .slider-main {
        width: 100%;
    }

    .title {
        margin-bottom: 15px;
        font-size: 14px;
    }

    .hide-dot .vue-slider-dot {
        display: none !important;
    }

     @media only screen and (max-width: 600px) {
        .vue-slider-dot {
            height: 20px !important; 
            width: 20px !important;
        }

        .vue-slider-dot-handle {
            border: 5px solid #fff;
        }
    }
</style>