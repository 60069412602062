<template>
  <div>
      <button @click="buttonClicked()" class="dropdown-button"><sortsvg class="sort-icon"></sortsvg> Sort 
      <i data-feather="chevron-down" class="down-caret-icon"  @click.stop></i> </button>

        <transition name="slide-out" appear="">
            <ul class="dropdown" v-if="showDropdown" @click.stop v-on-clickaway="clickedOutside">
                <li class="dropdown-item" v-for="(option, i) in options" :class="classes[i]" :key="option" 
                    @click="clickedItem(i, option)"> 
                    <checksvg class="check-icon" ></checksvg> {{ option }} </li>
            </ul>
        </transition>
  </div>
</template>

<script>
import sortsvg from '../assets/sort-two.svg';
import checksvg from '../assets/check.svg';
import feather from 'feather-icons';
import { mixin as clickaway } from "vue-clickaway";


export default {
    props: ['options', 'default'],
    data(){
        return {
            showDropdown: false,
            classes: [],
            slideAround: ""
        }
    },
    mixins: [ clickaway ],
    mounted(){
        feather.replace();
        this.options.map(() => {
            this.classes.push("");
        });

        this.classes.splice(this.options.indexOf(this.default), 1, "selected");
    },
    methods: {
        doCheck(i){
            return i === 1;
        },
        buttonClicked(){
            this.showDropdown = !this.showDropdown;

            /*this.slideAround = "go-crazy";

            setTimeout(()=>{
                this.slideAround = "";
            }, 200);*/
        },
        clickedItem(index, option){
            for(var i = 0; i < this.classes.length; i++){
                this.classes.splice(i, 1, "");
            }

            this.classes.splice(index, 1, "selected");

            this.$emit("item-clicked", option);
            this.showDropdown = false; 
        },
        clickedOutside(){
            this.showDropdown = false;
        }
    },
    components: {
        sortsvg,
        checksvg
    }
}
</script>

<style>
    .dropdown-button {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 35px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
        cursor: pointer;
        color: #C6C6C6;
        font-weight: 500;
        background-color: #2D3465;
        border: 1px solid #1F2444;
        transition: all 200ms ease;
    }

    .dropdown-button:hover {
        transform: translateY(-2px);
        border-color: #ff6600;
    }

    .dropdown-button:active {
        transform: scale(0.8);
    }

    .dropdown {
        position: fixed;
        z-index: 200;
        background-color: #2D3465;
        margin-top: 15px;
        border-radius: 5px;
        border: 1px solid #1F2444;
        box-shadow: 0px 0px 8px 0px #00000073;
    }

    .slide-out-enter-active, .slide-out-leave-active {
        transition: all .2s ease;
    }

    .slide-out-enter, .slide-out-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

    .selected .check-icon {
        visibility: visible;
        animation: showCheck 200ms ease-out;
    }

    .selected.dropdown-item {
        color: #fff;
    }

    .check-icon {
        visibility: hidden;
    }

    @keyframes showCheck {
        0% {
            opacity: 0;
            transform: scale(0.2);
        }

        100% {
            opacity: 1;
            transform: scale(1.0);
        }
    }

    .go-crazy {
        animation: slideAround 200ms ease-out;

    }

    @keyframes slideAround {
        0% {
            transform: translateY(15px);
        }

        25% {
            opacity: 0;
        }

        50% {
            transform: translateY(0px);
        }
        100% {
            opacity: 1;
        }
    }


    .down-caret-icon {
        height: 15px;
        width: 15px;
        stroke-width: 3px;
        margin-left: 15px;
        margin-bottom: -3px;
    }

    .sort-icon {
        margin-bottom: -2px;
    }

    .check-icon {
        color: rgb(6, 208, 6);
        margin-bottom: -3px;
        margin-right: 5px;
    }

    .dropdown-item {
        color: rgb(154, 154, 154);
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;
        font-size: 14px;
    }

    .dropdown-item:hover {
        background-color: #ff66003c;
        
    }

    li {
        list-style: none;
    }
</style>