<template>
    <div class="tension-root tension" :class="enabled" @click="doClick()">
        <span class="tension-title" >{{ title }}</span>
    </div>    
</template>
<script>
export default {
    props: ["title", "enabled"],
    methods: {
        doClick(){
            this.$emit("tension-clicked")
        }
    }
}
</script>
<style>
    .tension-root {
        border: 1px solid #abbef07a;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 3px;
        border-radius: 4px;
        cursor: pointer;
    }

    .tension-root:hover {
        color: #fff;
        border: 2px solid #FF6600
    }

    .tension-title:hover {
        color: #fff;
    }

    .tension-title {
        color: #b9bdd370;
        font-size: 11px;
    }

    .tension-root.enabled {
        border-width: 2px;
        border-color: #05B842;
    }

    .tension-root.enabled .tension-title{
        color: #05B842;
        font-weight: 450;
    }

</style>