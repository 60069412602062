<template>
    <div class="sens-container">
        <div v-if="isLoading" class="alc-loading-container">
            <span class="alc-loading-text">Loading ALC Setting. Please wait.. </span>
            <HollowDotsSpinner :animation-duration="1000" :dot-size="10" :dots-num="4" color="#ff6600" ></HollowDotsSpinner>
        </div>
        <div v-else>
        <div class="action-bar">
            <div class="sens-title" id="sensTitle" ref="senstitle">{{ alc.title }}</div>
            <div class="action-buttons">
                <button class="save-button action-button" @click="editAlc" v-if="!isInbuilt()"> 
                    <i class="edit-icon button-icon" data-feather="edit-2"></i>Edit </button>
                <button class="share-button action-button" @click="showShareAlcDialog()" :class="toggleShare">
                    <share-icon class="share-icon button-icon"></share-icon>
                    <!--<i class="share-icon button-icon" data-feather="share-2"></i>--> Share </button>

                <div class="close-alcview" @click="close()"><i class="close-alc-icon" data-feather="x" ></i></div>
                <!--<button class="cancel-button action-button" > <i class="cancel-icon button-icon" data-feather="x"></i> Cancel </button>-->
            </div>
        </div>
        <div class="title-sens-layer">
            
            <transition appear
                name="slide-fade">
                
                <div class="sens-card">
                    <!--<button class="action-button alc-action clear-button" @click="clear()" > Clear </button>
                    <button class="action-button alc-action reset-button" @click="reset()"> Reset to default </button> -->
                    
                    <alcslider :title="'Deadzone'" :alcmax="50"  v-model="alc.deadzone.value" :interactive="false"></alcslider>
                    <alcslider :title="'Outer Threshold'" :alcmax="31" v-model="alc.outertreshold.value" :interactive="false"></alcslider>
                    <alcslider :title="'Response Curve'" :alcmax="30" v-model="alc.responsecurve.value" :interactive="false"></alcslider>

                    <span class="sens-category">Hipfire Settings</span>

                    <alcslider :title="'Yaw Speed'" :alcmax="500"  v-model="alc.yaw.value" :interactive="false"></alcslider>
                    <alcslider :title="'Pitch Speed'" :alcmax="500" v-model="alc.pitch.value" :interactive="false"></alcslider>

                    <alcslider :title="'Turning Extra Pitch'" :alcmax="250"  v-model="alc.turningpitch.value" :interactive="false"></alcslider>
                    <alcslider :title="'Turning Extra Yaw'" :alcmax="250"  v-model="alc.turningyaw.value" :interactive="false"></alcslider>

                    <alcslider :title="'Turning Ramp Up Time'" :alcmax="100" v-model="alc.turningrampup.value" :interactive="false"></alcslider>
                    <alcslider :title="'Turning Ramp up Delay'" :alcmax="100" v-model="alc.turningdelay.value" :interactive="false"></alcslider>

                    <span class="sens-category">ADS Settings</span>

                    <alcslider :title="'ADS Yaw Speed'" :alcmax="500" v-model="alc.adsyaw.value" :interactive="false"></alcslider>
                    <alcslider :title="'ADS Pitch Speed'" :alcmax="500"  v-model="alc.adspitch.value" :interactive="false"></alcslider>

                    <alcslider :title="'ADS Turning Extra Pitch'" :alcmax="250" v-model="alc.adsturningpitch.value" :interactive="false"></alcslider>
                    <alcslider :title="'ADS Turning Extra Yaw'" :alcmax="250"  v-model="alc.adsturningyaw.value" :interactive="false"></alcslider>

                    <alcslider :title="'ADS Turning Ramp Up Time'" :alcmax="100" v-model="alc.adsturningrampup.value" :interactive="false"></alcslider>
                    <alcslider :title="'ADS Turning Ramp up Delay'" :alcmax="100" v-model="alc.adsturningdelay.value" :interactive="false"></alcslider>
                </div>
            </transition>

            <transition appear
                >
                <div class="meta-info" :class="hideColumnClass">
                    <div class="tension-meta-item meta-info-item"> 
                        <div class="meta-title-container">
                            <brand-apple-arcade-icon class="meta-info-icon"/> 
                            <span class="meta-info-title"> Stick Tension </span>
                        </div>

                        <span class="meta-info-value">{{ alc.tension }}</span> 
                    </div>
                    
                    <div class="platform-meta-item meta-info-item">
                       <div class="meta-title-container">
                            <apps-icon class="meta-info-icon"/>
                            <span class="meta-info-title">Platform</span>
                       </div>

                       <span class="meta-info-value"> {{ alc.platform }}</span>
                    </div>

                    <div class="controller-meta-item meta-info-item">
                       <div class="meta-title-container">
                            <device-gamepad2-icon class="meta-info-icon"></device-gamepad2-icon> 
                            <span class="meta-info-title">Controller name</span>
                       </div>

                       <span class="meta-info-value"> {{ alc.controller }}</span>
                    </div>

                    <div class="note-meta-item meta-info-item" v-if="alc.description">
                        <div class="meta-title-container">
                            <clipboard-text-icon class="meta-info-icon"></clipboard-text-icon>
                            <span class="meta-info-title">Note</span>
                        </div>

                        <span class="alc-note meta-info-value"><span ref="note"></span><!--{{ alc.description }}--></span>
                    </div>

                    <div class="video-meta-item meta-info-item" v-if="alc.videolink" >
                        <div class="meta-title-container"> 
                            <movie-icon class="meta-info-icon"></movie-icon>
                            <span class="meta-info-title"> ALC Highlight</span>
                        </div>
                        
                        <a class="meta-info-value"  :href="alc.videolink" target="_blank">{{ alc.videolink }}</a>
                        <!--<youtube class="alc-video" :video-id="videoId" player-width="300" player-height="200"></youtube>-->
                    </div>

                    <div class="delete-container">
                        <button class="delete-button" @click="showDeleteAlcDialog" v-if="!isInbuilt()"> <i class="delete-icon" data-feather="trash-2"></i>Delete ALC Setting</button>
                    </div>
                    
                </div>
            </transition>
        </div>
        </div>
    </div>
    
</template>

<script>
import alcslider from './alcslider.vue';
//import tension from './tension.vue';
import feather from 'feather-icons';
import { marked } from 'marked';
import { mapGetters, mapState } from 'vuex';
import { HollowDotsSpinner } from 'epic-spinners';

import { mapActions } from 'vuex';
import { defaultALCs } from '../local';
import inbuilts from '../static';
import { BrandAppleArcadeIcon, DeviceGamepad2Icon, ClipboardTextIcon, MovieIcon, AppsIcon, ShareIcon } from "vue-tabler-icons";

import "../assets/alcview.css";

export default {
    data(){
        return {
            hideColumnClass: "",
            videoId: "",
            sharedAlc: {},
            playerAlc: {},
            toggleShare: "",
            isLoading: false,
        }
    },
    mounted(){
        // console.log(this.alc);
        feather.replace();

        /* this.bindAlcs().then(()=> {
            console.log(this.sharedAlcs);
        })*/

        this.showMetaDelay();

        // this.videoId = getIdFromURL("https://www.youtube.com/watch?v=G_tFzBiHPtw");

        // console.log(this.$route.params);

        if(this.$route.query.shareid) {
            this.toggleShare = "show-share-all";
            this.loadAlc();
        } else if (this.$route.query.player){
            this.toggleShare = "show-share-all";
        }

        document.title = this.alc.title;

        this.$gtag.pageview("/alc");

        if (this.alc.description){
            this.$refs.note.innerHTML = marked.parse(this.alc.description);
        }
    },
    methods: {
        ...mapActions([
            "loadAlcFromID",
            "bindAlcs",
            "getFromFirestore"
        ]),
        showMetaDelay(){
            setTimeout(()=> {
                this.hideColumnClass = "show-meta-info";
            }, 100);
        },
        async loadAlc(){
            this.isLoading = true;
            document.title = "Loading ALC..";

            this.getFromFirestore(this.$route.query.shareid).then((doc) => {
                
                if (doc.exists){
                    this.sharedAlc = doc.data();
                    this.isLoading = false;

                    document.title = this.alc.title;
                } else {
                    this.$router.replace({ path: "/notfound"})
                }
                
            })           
            

            /* this.loadAlcFromID(this.$route.params.shareid).then((response) => {
                this.sharedAlc = response.data.data[0].alc;
                
                setTimeout(() => {
                    this.isLoading = false;// hide page loader
                    // refresh icons
                }, 5 * 1000);
                 
            }).catch((err) => {
                console.log(err);
                this.isLoading = false;
            })*/
        },
        isInbuilt(){
            return this.$route.query.player || this.$route.query.shareid;
        },
        editAlc(){
            this.$router.push({path: "/addalc/" + this.$route.params.id});
        },
        close(){
            this.$router.go(-1);
        },
        showDeleteAlcDialog(){
            this.$store.commit("putShowDeleteAlcDialog", true);
            this.$store.commit("putAlcIndexToDelete", parseInt(this.$route.params.id));
        },
        showShareAlcDialog(){
            if(this.$route.query.player) {
                this.$store.commit("putBuiltInNameToShare", this.$route.query.player);
            } else if (this.$route.query.shareid){
                this.$store.commit("putBuiltInNameToShare", this.$route.query.shareid);
            }

            this.$store.commit("putShowShareAlcDialog", true);
            this.$store.commit("putAlcIndexToShare", parseInt(this.$route.params.id));
        }
    },
    computed: {
        ...mapGetters[
            'getAlc'
        ],
        ...mapState([
            "sharedAlcs"
        ]),
        alc(){
            if(this.$route.query.shareid){
                if (Object.keys(this.sharedAlc).length){
                    return this.sharedAlc;
                } else {
                    return inbuilts.alc
                }
            }else if (this.$route.query.player){
                return defaultALCs.filter((x) => x.title.toLowerCase() === this.$route.query.player)[0]
            } else {
                return this.$store.getters.getAlc(this.$route.params.id);
            }
        }
    },
    components: {
        alcslider,
        BrandAppleArcadeIcon,
        DeviceGamepad2Icon,
        MovieIcon,
        ClipboardTextIcon,
        AppsIcon,
        ShareIcon,
        HollowDotsSpinner
        //descriptionsvg
    }
    
}
</script>

<style>

.icon-park path, .icon-park rect {
    stroke-width: 5px;
}

.platform {
    margin-top: 30px;
    display: flex;
}

.meta-info-item {
    font-feature-settings: "cv11", "ss01";
    line-height: 1.7;
    letter-spacing: .2px;
}

.meta-info-title {
    margin-left: 5px;
    font-size: 13px;
}

.video-meta-item.meta-info-item {
    max-width: 15em;
}

.meta-info-item a {
    word-wrap: break-word;
}

.meta-title-container {
    display: flex;
    align-items: center;
    color: #d6d6d672;
}

.meta-info-icon {
    stroke: 3px;
}

.meta-info-value {
    margin-top: 5px;
    color: #d6d6d6f1;
    font-weight: 550;
    text-decoration: none;
}

.meta-info p:nth-child(1) {
    margin-top: 0px;
}

.meta-info-icon {
    height: 15px;
    width: 15px;
}

  a.meta-info-value:hover {
    text-decoration: underline;
  }

.value-name {
    margin-left: 10px;
    margin-top: -3px;
    display: inline-block;
    justify-self: center;
    color: #d6d6d686;
    font-size: 13px;
    font-weight: 550;
}

.platform-meta-item, .note-meta-item, .video-meta-item, .controller-meta-item {
    margin-top: 15px;
}

.alc-loading-text {
    color: #fff;
    margin-bottom: 15px;
}

.alc-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px);
}

.tension-value {
    font-weight: 550;
}

.alc-note {
    font-size: 15px;
    font-weight: normal;
    font-feature-settings: "cv11", "ss01";
    line-height: 1.7;
    letter-spacing: .2px;
}

.alc-video {
    margin-top: 15px;
}

.video-label {
    display: flex;
}

.edit-icon {
    height: 12px;
    width: 14px;
    margin-right: 5px;
    margin-bottom: -2px;
}

.close-alc-icon {
    margin-top: 3px;
    color: #d6d6d686;
    cursor: pointer;
    transition: all 200ms ease;
}

.close-alc-icon:hover {
    transform: translateY(-2px);
    color: #FF6600;
}

.close-alc-icon:active, .delete-button:active {
    transform: scale(0.9);
}

.share-icon {
    color: #FF6600;
}

.share-button {
    margin-left: 30px;
}

.delete-button {
    margin-top: 30px;
    
    height: 35px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 4px;
    border-width: 0px;

    cursor: pointer;
    font-weight: 500;
    color: #ff1e00;
    background: #ff1e0022;
    border-style: outset;

    justify-content: center;
    transition: all 200ms ease;
}

.delete-container {
    display: flex;
    justify-content: center;
}

.delete-button:hover {
    border: 1px solid #ff1e00;
}

.delete-icon {
    height: 14px;
    width: 14px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .value-name {
        font-weight: 400;
        font-size: 15px;
    }

    .share-button {
        /* display: none; */
    }

    .save-button {
        margin-left: 15px;
    }

    .show-share-all.share-button {
        display: block;
    }
}

</style>