<template>
    <div class="main-container">
        <div class="main-root">
            <deletealcdialog-vue v-if="showDeleteAlcDialog"></deletealcdialog-vue>
            <sharealcdialog-vue v-if="showShareAlcDialog"></sharealcdialog-vue>
            <header>
                <div class="nav-bar"><router-link class="nav-title" to="/">
                        <!--<img src="../assets/logo.svg"/>-->
                        <logosvg /> ALC MANAGER
                    </router-link>
                    <div class="nav-link-container">
                        <!--<span class="nav-link support-dev" @click="toggleDonationDropdown()" v-if="false">SUPPORT THE DEV 
                            <i data-feather="chevron-down" class="down-caret"></i> </span>
                        <donationdropdown-vue v-if="toggleDonation" @click.stop v-on-clickaway="closeDonationDropdown"></donationdropdown-vue>
                        
                        <router-link class="nav-link popular" to="/popular">POPULAR ALCS</router-link>
                        <router-link class="nav-link guide" to="/guide">ALC GUIDE</router-link>
                        -->
                        <menu-vue :options="menuOptions"></menu-vue>
                    </div>

                </div>
            </header>

            <router-view class="router-view"></router-view>
        </div>
        <footer class="fill-all">
            <div class="social-container">
                <div class="owner-sig">Made by <a href="https://twitter.com/iamzouga" target="_blank"
                        class="twitter-redirect">
                        <i data-feather="twitter" class="twitter-icon"></i> @iamzouga</a></div>
                <div class="owner-sig mail">Business enquiries: <a href="mailto: zolaballantye@gmail.com" class="mail-link">
                        send an email</a></div>
            </div>

            <div class="owner-sig get-updates">Get updates for ALC manager on <a href="https://discord.gg/QwtXZhwms7"
                    target="_blank" class="discord-link">
                    <brand-discord-icon class="discord-icon"></brand-discord-icon>discord</a>
            </div>
        </footer>

    </div>
</template>

<script>
import deletealcdialogVue from "./deletealcdialog.vue"
import sharealcdialogVue from "./sharealcdialog.vue";
import donationdropdownVue from "./donationdropdown.vue";
import menuVue from "./menu.vue";

import feather from 'feather-icons';
import logosvg from '../assets/logo.svg';
import { BrandDiscordIcon } from "vue-tabler-icons";
import { mixin as clickaway } from 'vue-clickaway';
import { mapState } from "vuex";

export default {
    data() {
        return {
            toggleDonation: false,
            menuOptions: [
                {
                    name: "POPULAR ALCS",
                    path: "/popular"
                },
                {
                    name: "ALC GUIDE",
                    path: "/guide"
                }
            ]
        }
    },
    mixins: [clickaway],
    mounted() {
        feather.replace();
    },
    methods: {
        closeDonationDropdown() {
            // console.log("called")
            this.toggleDonation = false;
        },
        toggleDonationDropdown() {
            this.toggleDonation = !this.toggleDonation;
        }
    },
    components: {
        deletealcdialogVue,
        sharealcdialogVue,
        // eslint-disable-next-line vue/no-unused-components
        donationdropdownVue,
        menuVue,
        BrandDiscordIcon,
        logosvg
    },
    computed: {
        ...mapState([
            "showDeleteAlcDialog",
            "showShareAlcDialog"])
    }
}
</script>

<style lang="css">
.main-root {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 60px);
    background-color: #353D73;
    /* background-image: url("../assets/Controller_button_icons.svg"); */
}

.main-container {
    display: flex;
    flex-direction: column;
}

footer {
    height: 60px;
    clear: both;
    display: flex;
    color: #D6D6D6;
    background-color: #303664;
    padding-left: 300px;
    padding-right: 300px;
    z-index: 100;
}

.social-container {
    justify-self: center;
    align-self: center;
}

.twitter-icon {
    height: 12px;
    width: 12px;
    margin-bottom: -2px;
}

.discord-link {
    color: #5865F2;
    text-decoration: none;
    font-weight: 550;
}

.discord-icon {
    height: 12px;
    width: 12px;
    margin-bottom: -2px;
}

.owner-sig {
    font-size: 12px;
}

.get-updates {
    margin-left: auto;
    align-self: center;
}

.mail-link {
    color: #D6D6D6;
}

.twitter-redirect {
    color: #1d9bf0;
    text-decoration: none;
    margin-left: 5px;
}

.twitter-redirect:hover,
.discord-link:hover {
    text-decoration: underline;
}

body {
    padding: 0;
    margin: 0px;
    background-color: #1F2444;
    font-family: 'Open Sans';
    height: 100vh;
}

.nav-bar {
    display: flex;
    background-color: #1F2444;
    color: #D6D6D6;
    height: 60px;
    padding-left: 300px;
    padding-right: 300px;
    position: fixed;
    z-index: 100;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    /* position: sticky;
        top: 0;
        z-index: 100;*/
}

/*.nav-link {
        align-self: center;
        font-size: 12px;
        font-weight: 550;
        text-decoration: none;
        color: #d6d6d6a5;
    }*/

.nav-link.support-dev {
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

/*.nav-link.guide {
        margin-left: 30px;
    }*/

.nav-link.support-dev .down-caret {
    margin-left: 5px;
    height: 15px;
    width: 15px;
}

.nav-link-container {
    margin-left: auto;
    align-self: center;
    display: flex;
}

.fill-all {
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
}

.nav-link:hover {
    text-decoration: underline;
    color: #fff;
}

.router-view {
    margin-top: 60px;
}


.nav-title {
    align-self: center;
    font-weight: 600;
    text-decoration: none;
    color: #D6D6D6;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-title:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .nav-bar {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
    }

    .main-root {
        min-height: 100vh;
    }

    .router-view {
        margin-top: 0px;
    }

    footer {
        height: auto;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-direction: column;
    }

    .social-container {
        align-self: flex-start;
    }

    .owner-sig.mail {
        margin-top: 5px;
    }

    .get-updates {
        align-self: flex-start;
        margin: 0px;
        margin-top: 5px;
    }
}
</style>